import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const FARM_DOCUMENTS_PATH = "farm_documents"

export const FARM_DOCUMENTS_LABEL_MAP: LabelMap = new Map([
  ["farm_documents[*].type", ""],
  ["farm_documents[*].farm_name", ""],
  ["farm_documents[*].name", ""],
  ["farm_documents[*].location_hint", ""],
  ["farm_documents[*].date_created", ""],
  ["farm_documents[*].last_updated", ""],
  ["farm_documents[*].attachment", ""],
  ["farm_documents[*].notes", ""],
])
