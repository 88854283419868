import type {LabelMap, LabelMaps} from "@/library/models/app-fields/app-field-labels.interface"

export const BUSINESS_DOCUMENTS_PATH = "business_documents"

export const BUSINESS_DOCUMENTS_LABEL_MAP: LabelMap = new Map([
  ["business_documents[*].type", ""],
  ["business_documents[*].business_name", ""],
  ["business_documents[*].name", ""],
  ["business_documents[*].location_hint", ""],
  ["business_documents[*].date_created", ""],
  ["business_documents[*].last_updated", ""],
  ["business_documents[*].attachment", ""],
  ["business_documents[*].notes", ""],
])
