import {
  DECEASED_PERSONAL_INFO_LABEL_MAP,
  DECEASED_CONTACT_INFO_LABEL_MAP,
  DECEASED_IMPORTANT_DOCS_LABEL_MAP,
  DECEASED_PARENT_INFO_LABEL_MAP,
  DECEASED_END_OF_LIFE_INFO_LABEL_MAP,
} from "@/library/domain/app-fields/label-maps/personal-information"
import {FUNERAL_HOME_LABEL_MAP} from "@/library/domain/app-fields/label-maps/funeral-home"
import {
  SPOUSE_CONTACT_INFO_LABEL_MAP,
  SPOUSE_IMPORTANT_DATES_LABEL_MAP,
  SPOUSE_MARRIAGE_INFO_LABEL_MAP,
  SPOUSE_PARENT_INFO_LABEL_MAP,
  SPOUSE_PERSONAL_INFO_LABEL_MAP,
} from "@/library/domain/app-fields/label-maps/spouse"
import {APPLICANT_LABEL_MAP} from "@/library/domain/app-fields/label-maps/applicant"
import {PAYMENT_LABEL_MAP} from "@/library/domain/app-fields/label-maps/payment"
import {ESTATE_REPS_LABEL_MAP} from "@/library/domain/app-fields/label-maps/estate-reps"
import {CHILDREN_LABEL_MAP} from "@/library/domain/app-fields/label-maps/children"
import {
  assign,
  chain,
  delay,
  each,
  fill,
  find,
  findKey,
  first,
  includes,
  indexOf,
  map,
  mapValues,
  range,
  reject,
  startsWith,
  transform,
  values,
  zipObject,
} from "lodash"
import type {LabelMap} from "@/library/models/app-fields/app-field-labels.interface"
import type {IAppField} from "@/library/models/app-fields/app-field.interface"
import {createKeyFromSignature, type TAppFieldValueKey} from "@/library/stores/app-field-value"
import {isOfList} from "@/library/stores/app-field-value"
// todo: move this work having to do with labels and localization into app fields; we should be using something else
import {CONTACTS_LABEL_MAP} from "@/library/domain/app-fields/label-maps/contact"
import {ID_DOCUMENT_LABEL_MAP} from "@/library/constants/licenses"
import {KEY_DOCUMENTS_LABEL_MAP} from "@/library/constants/documents"
import {INSURANCE_LABEL_MAP} from "@/library/constants/insurance"
import {FINANCES_LABEL_MAP} from "@/library/constants/finances"
import {UTILITIES_LABEL_MAP} from "@/library/constants/utilities"
import {PROPERTIES_LABEL_MAP} from "@/library/constants/properties"
import {ACCOUNTS_LABEL_MAP} from "@/library/constants/accounts"
import i18next from "i18next"
import {END_OF_LIFE_LABEL_MAP} from "@/library/constants/end-of-life"
import {OBITUARY_LABEL_MAP} from "@/library/constants/obituary"
import {WILL_LABEL_MAP} from "@/library/constants/will"
import {
  FINAL_WISHES_FAMILY_LABEL_MAP,
  FINAL_WISHES_PERSONALIZATION_LABEL_MAP,
  FINAL_WISHES_PREPARATION_LABEL_MAP,
  FINAL_WISHES_RESTING_PLACE_LABEL_MAP,
  FINAL_WISHES_SERVICE_LABEL_MAP,
} from "@/library/constants/final-wishes"
import {useCaseStore} from "@/library/stores/case"
import {BUSINESS_DOCUMENTS_LABEL_MAP} from "@/library/constants/business_documents"
import {FARM_DOCUMENTS_LABEL_MAP} from "@/library/constants/farm_documents"

export interface IAppFieldPathSet {
  id: string | TSectionTypes
  paths: IAppField["path"][]
}

export interface IAppFieldKeySet {
  id: string | TSectionTypes
  keys: TAppFieldValueKey[]
}

export interface ICompositeAppFieldKeySet {
  id: string | TSectionTypes
  keySets: (IAppFieldKeySet | IRepeatableAppFieldKeySets)[]
}

// todo: rename to IRepeatableAppFieldPathSet
export interface IRepeatableAppFieldKeySets {
  id: string | TSectionTypes // todo: this could replace `repeatable_by` to generify the structure a bit
  repeatable_by: TRepeatableTypes
  keySets: IAppFieldKeySet[]
  // Estate Representative 1
  // Estate Representative 2
  // ...
}

export enum TRepeatableTypes {
  EstateReps = "estate_reps[*]",
  Children = "children[*]",
  Contacts = "contact[*]",
  IdDocuments = "id_document[*]",
  KeyDocuments = "key_document[*]",
  Insurance = "insurance[*]",
  FinancialInformation = "financial_information[*]",
  Utilities = "utility[*]",
  Property = "property[*]",
  Memberships = "account[*]",
  BusinessDocuments = "business_documents[*]",
  FarmDocuments = "farm_documents[*]",
}

export function isKeySet(x: IAppFieldKeySet | IRepeatableAppFieldKeySets): x is IAppFieldKeySet {
  return "keys" in x
}

export function isRepeatableKeySets(x: IAppFieldKeySet | IRepeatableAppFieldKeySets): x is IRepeatableAppFieldKeySets {
  return "keySets" in x
}

export function startsWithRepeatablePath(path: IAppField["path"]) {
  return !!find(values(TRepeatableTypes), r => startsWith(path, r))
}

// This is linked to frontend/projects/admin/src/app/models/digital-vault-section-type.interface.ts
// where the VAULT_SECTION_KEYS align with the TSectionTypes found below. Please ensure that changes
// to the digital vault TSectionTypes below are appropriately translated to the Admin app interface

export enum TSectionTypes {
  // form filler
  DECEASED_PERSONAL_INFO = "deceased_personal_info",
  DECEASED_CONTACT_INFO = "deceased_contact_info",
  DECEASED_IMPORTANT_DOCS = "deceased_important_docs",
  DECEASED_PARENT_INFO = "deceased_parent_info",
  DECEASED_END_OF_LIFE_INFO = "deceased_end_of_life_info",

  APPLICANT = "applicant",
  FUNERAL_HOME = "funeral_home",
  PAYMENT = "payment",

  SPOUSE_PERSONAL_INFO = "spouse_personal_info",
  SPOUSE_CONTACT_INFO = "spouse_contact_info",
  SPOUSE_MARRIAGE_INFO = "spouse_marriage_info",
  SPOUSE_IMPORTANT_DATES = "spouse_important_dates",
  SPOUSE_PARENT_INFO = "spouse_parent_info",
  CHILDREN = "children",

  // estate reps
  ESTATE_REPS = "estate_reps",

  // digital vault -- Listings
  CONTACTS = "contacts", //contacts
  ID_DOCUMENTS = "id_documents", // licenses
  KEY_DOCUMENTS = "key_documents", // legal
  INSURANCE = "insurance", // insurance
  FINANCIAL_INFO = "financial_information", // finances
  UTILITIES = "utilities", // expenses
  PROPERTY = "property", // property
  MEMBERSHIPS = "memberships", // accounts
  // EXECUTORS = "executors", // executors
  // DIGITAL = "digital", // digital
  BUSINESS_DOCUMENTS = "business_documents", // business
  FARM_DOCUMENTS = "farm_documents",

  // digital vault - Non listings
  PROFILE = "profile", // composite of [DECEASED_PERSONAL_INFO, DECEASED_CONTACT_INFO, DECEASED_IMPORTANT_DOCS, DECEASED_PARENT_INFO]
  SPOUSE = "spouse", // composite of [SPOUSE_PERSONAL_INFO, SPOUSE_CONTACT_INFO, SPOUSE_MARRIAGE_INFO, SPOUSE_IMPORTANT_DATES, SPOUSE_PARENT_INFO]
  FUNERAL = "funeral", // composite of [END_OF_LIFE, FINAL_WISHES_RESTING_PLACE, FINAL_WISHES_FAMILY, FINAL_WISHES_SERVICE, FINAL_WISHES_PERSONALIZATION, FINAL_WISHES_PREPARATION, OBITUARY, WILL]
  CASE_TASK_FORM_FILLER = "case_task_form_filler", // composite of dynamic mappings and corresponding sections
  CASE_TASK_DIGITAL_VAULT = "case_task_digital_vault", // composite of dynamic mappings and corresponding sections
  LEGAL = "legal", // composite of KEY_DOCUMENTS, WILL, DECEASED_END_OF_LIFE_INFO

  // funeral
  END_OF_LIFE = "end_of_life", // funeral
  FINAL_WISHES_RESTING_PLACE = "final_wishes_resting_place", // funeral
  FINAL_WISHES_FAMILY = "final_wishes_family", // funeral
  FINAL_WISHES_SERVICE = "final_wishes_service", // funeral
  FINAL_WISHES_PERSONALIZATION = "final_wishes_personalization", // funeral
  FINAL_WISHES_PREPARATION = "final_wishes_preparation", // funeral
  OBITUARY = "obituary", // funeral
  WILL = "will", // funeral
}

/** @deprecated */
export const REPEATABLE_APP_FIELD_CONTAINER_LABELS_BY_PATH: Record<string, string> = {
  // todo: this can be reduced to a basic list of repeatables
  [TRepeatableTypes.Children]: "",
  [TRepeatableTypes.EstateReps]: "",
  [TRepeatableTypes.Contacts]: "",
  [TRepeatableTypes.IdDocuments]: "",
  [TRepeatableTypes.KeyDocuments]: "",
  [TRepeatableTypes.Insurance]: "",
  [TRepeatableTypes.FinancialInformation]: "",
  [TRepeatableTypes.Utilities]: "",
  [TRepeatableTypes.Property]: "",
  [TRepeatableTypes.Memberships]: "",
  [TRepeatableTypes.BusinessDocuments]: "",
  [TRepeatableTypes.FarmDocuments]: "",
}

export const LABEL_MAPS_BY_SECTION_ID: Record<TSectionTypes, LabelMap> = {
  // form filler
  [TSectionTypes.DECEASED_PERSONAL_INFO]: new Map([...DECEASED_PERSONAL_INFO_LABEL_MAP]),
  [TSectionTypes.DECEASED_CONTACT_INFO]: new Map([...DECEASED_CONTACT_INFO_LABEL_MAP]),
  [TSectionTypes.DECEASED_IMPORTANT_DOCS]: new Map([...DECEASED_IMPORTANT_DOCS_LABEL_MAP]),
  [TSectionTypes.DECEASED_PARENT_INFO]: new Map([...DECEASED_PARENT_INFO_LABEL_MAP]),
  [TSectionTypes.DECEASED_END_OF_LIFE_INFO]: new Map([...DECEASED_END_OF_LIFE_INFO_LABEL_MAP]),

  [TSectionTypes.APPLICANT]: new Map([...APPLICANT_LABEL_MAP]),
  [TSectionTypes.FUNERAL_HOME]: new Map([...FUNERAL_HOME_LABEL_MAP]),
  [TSectionTypes.PAYMENT]: new Map([...PAYMENT_LABEL_MAP]),

  [TSectionTypes.SPOUSE_PERSONAL_INFO]: new Map([...SPOUSE_PERSONAL_INFO_LABEL_MAP]),
  [TSectionTypes.SPOUSE_CONTACT_INFO]: new Map([...SPOUSE_CONTACT_INFO_LABEL_MAP]),
  [TSectionTypes.SPOUSE_MARRIAGE_INFO]: new Map([...SPOUSE_MARRIAGE_INFO_LABEL_MAP]),
  [TSectionTypes.SPOUSE_IMPORTANT_DATES]: new Map([...SPOUSE_IMPORTANT_DATES_LABEL_MAP]),
  [TSectionTypes.SPOUSE_PARENT_INFO]: new Map([...SPOUSE_PARENT_INFO_LABEL_MAP]),

  [TSectionTypes.CHILDREN]: new Map([...CHILDREN_LABEL_MAP]),

  // estate reps
  [TSectionTypes.ESTATE_REPS]: new Map([...ESTATE_REPS_LABEL_MAP]),

  // digital vault
  [TSectionTypes.CONTACTS]: new Map([...CONTACTS_LABEL_MAP]),
  [TSectionTypes.ID_DOCUMENTS]: new Map([...ID_DOCUMENT_LABEL_MAP]),
  [TSectionTypes.KEY_DOCUMENTS]: new Map([...KEY_DOCUMENTS_LABEL_MAP]),
  [TSectionTypes.INSURANCE]: new Map([...INSURANCE_LABEL_MAP]),
  [TSectionTypes.FINANCIAL_INFO]: new Map([...FINANCES_LABEL_MAP]),
  [TSectionTypes.UTILITIES]: new Map([...UTILITIES_LABEL_MAP]),
  [TSectionTypes.PROPERTY]: new Map([...PROPERTIES_LABEL_MAP]),
  [TSectionTypes.MEMBERSHIPS]: new Map([...ACCOUNTS_LABEL_MAP]),
  [TSectionTypes.BUSINESS_DOCUMENTS]: new Map([...BUSINESS_DOCUMENTS_LABEL_MAP]),
  [TSectionTypes.FARM_DOCUMENTS]: new Map([...FARM_DOCUMENTS_LABEL_MAP]),

  // final wishes
  [TSectionTypes.END_OF_LIFE]: new Map([...END_OF_LIFE_LABEL_MAP]),
  [TSectionTypes.FINAL_WISHES_RESTING_PLACE]: new Map([...FINAL_WISHES_RESTING_PLACE_LABEL_MAP]),
  [TSectionTypes.FINAL_WISHES_FAMILY]: new Map([...FINAL_WISHES_FAMILY_LABEL_MAP]),
  [TSectionTypes.FINAL_WISHES_SERVICE]: new Map([...FINAL_WISHES_SERVICE_LABEL_MAP]),
  [TSectionTypes.FINAL_WISHES_PERSONALIZATION]: new Map([...FINAL_WISHES_PERSONALIZATION_LABEL_MAP]),
  [TSectionTypes.FINAL_WISHES_PREPARATION]: new Map([...FINAL_WISHES_PREPARATION_LABEL_MAP]),

  [TSectionTypes.OBITUARY]: new Map([...OBITUARY_LABEL_MAP]),
  [TSectionTypes.WILL]: new Map([...WILL_LABEL_MAP]),

  // [TSectionTypes.DIGITAL]: new Map(),
  // [TSectionTypes.EXECUTORS]: new Map(),
  // [TSectionTypes.BUSINESSES]: new Map(),

  // no-ops for composites but present to ensure typing and therefore comprehensiveness (unused or no direct mappings thus far)
  [TSectionTypes.PROFILE]: new Map(),
  [TSectionTypes.SPOUSE]: new Map(),
  [TSectionTypes.FUNERAL]: new Map(),
  [TSectionTypes.CASE_TASK_FORM_FILLER]: new Map(),
  [TSectionTypes.CASE_TASK_DIGITAL_VAULT]: new Map(),
  [TSectionTypes.LEGAL]: new Map(),
}

// todo: all of this magic is fine, but we need to make the labels aspect dynamic whereas paths and ordering can remain static
export const refreshLabelMaps = () => {
  // todo: push handler into setupi18n and maintain body as `refreshLabels`
  // refresh labels because they're not reactive right now; todo: do lookups JIT rather than storing in map

  const caseState = useCaseStore().activeCaseStatusWithTransitioned
  if (!caseState) {
    delay(refreshLabelMaps, 50)
    return
  }

  each(LABEL_MAPS_BY_SECTION_ID, (labelMap, sectionId) => {
    labelMap.forEach((_, path) => {
      const keyWithCaseState = `app_fields:${sectionId}_${useCaseStore().activeCaseStatusWithTransitioned}.${path}`
      const key = `app_fields:${sectionId}.${path}`
      const label = i18next.exists(keyWithCaseState) ? i18next.t(keyWithCaseState) : i18next.t(key)
      labelMap.set(path, label)
    })
  })

  GLOBAL_ORDERED_LABEL_MAP = generateGlobalOrderedLabelMap()
}

export const PATHS_BY_SECTION_ID = mapValues<Record<TSectionTypes, LabelMap>, IAppField["path"][]>(
  LABEL_MAPS_BY_SECTION_ID,
  labelMap => [...labelMap.keys()],
)

export const SECTIONS_IDS_BY_PATH: Record<IAppField["path"], TSectionTypes> = transform(
  PATHS_BY_SECTION_ID,
  (memo, paths: IAppField["path"][], section) => {
    const pathToSectionMap = zipObject(paths, fill(Array(paths.length), section)) // map every path to this same section
    assign(memo, pathToSectionMap)
  },
)

function generateGlobalOrderedLabelMap() {
  return new Map([
    ...LABEL_MAPS_BY_SECTION_ID.deceased_personal_info,
    ...LABEL_MAPS_BY_SECTION_ID.deceased_contact_info,
    ...LABEL_MAPS_BY_SECTION_ID.deceased_important_docs,
    ...LABEL_MAPS_BY_SECTION_ID.deceased_parent_info,
    ...LABEL_MAPS_BY_SECTION_ID.deceased_end_of_life_info,
    ...LABEL_MAPS_BY_SECTION_ID.funeral_home,
    ...LABEL_MAPS_BY_SECTION_ID.spouse_personal_info,
    ...LABEL_MAPS_BY_SECTION_ID.spouse_contact_info,
    ...LABEL_MAPS_BY_SECTION_ID.spouse_marriage_info,
    ...LABEL_MAPS_BY_SECTION_ID.spouse_important_dates,
    ...LABEL_MAPS_BY_SECTION_ID.spouse_parent_info,
    ...LABEL_MAPS_BY_SECTION_ID.children,
    ...LABEL_MAPS_BY_SECTION_ID.contacts,
    ...LABEL_MAPS_BY_SECTION_ID.applicant,
    ...LABEL_MAPS_BY_SECTION_ID.payment,
    ...LABEL_MAPS_BY_SECTION_ID.estate_reps,
    ...LABEL_MAPS_BY_SECTION_ID.id_documents,
    ...LABEL_MAPS_BY_SECTION_ID.key_documents,
    ...LABEL_MAPS_BY_SECTION_ID.insurance,
    ...LABEL_MAPS_BY_SECTION_ID.financial_information,
    ...LABEL_MAPS_BY_SECTION_ID.utilities,
    ...LABEL_MAPS_BY_SECTION_ID.property,
    ...LABEL_MAPS_BY_SECTION_ID.memberships,
    ...LABEL_MAPS_BY_SECTION_ID.end_of_life,
    ...LABEL_MAPS_BY_SECTION_ID.business_documents,
    ...LABEL_MAPS_BY_SECTION_ID.farm_documents,
    ...LABEL_MAPS_BY_SECTION_ID.final_wishes_resting_place,
    ...LABEL_MAPS_BY_SECTION_ID.final_wishes_family,
    ...LABEL_MAPS_BY_SECTION_ID.final_wishes_service,
    ...LABEL_MAPS_BY_SECTION_ID.final_wishes_personalization,
    ...LABEL_MAPS_BY_SECTION_ID.final_wishes_preparation,
    ...LABEL_MAPS_BY_SECTION_ID.obituary,
    ...LABEL_MAPS_BY_SECTION_ID.will,
  ])
}

export let GLOBAL_ORDERED_LABEL_MAP: LabelMap = generateGlobalOrderedLabelMap()

export const GLOBAL_ORDERED_PATHS = [...GLOBAL_ORDERED_LABEL_MAP.keys()]

export const MAX_ITEMS = 30

/**
 * Based on label map, create ordered sets of keys having indices populated, max-ing out at MAX_ITEMS;
 * (based on backend's AppFieldDataModelItemProvider::maxItemsForPath()).
 */
export function generateRepeatedSectionKeysFor(section: TSectionTypes, max = MAX_ITEMS): TAppFieldValueKey[] {
  const pathsForSection = [...LABEL_MAPS_BY_SECTION_ID[section].keys()] // todo: this stuff needs to go through form filler normalization process
  return chain(range(0, max))
    .map((i: number) => map(pathsForSection, path => convertPathToKeySignature(path, [i])))
    .map(signaturesForIndex => convertSignaturesToOrderedKeys(signaturesForIndex))
    .flatten()
    .value()
}

export function generateRepeatedKeySignaturesFor( // todo: consume in above function
  paths: TAppFieldValueKey["model"]["path"][],
  max = MAX_ITEMS,
): TAppFieldValueKey["signature"][] {
  // prettier-ignore
  return chain(range(0, max))
    .map((i: number) =>
      map(paths, path => isRepeatable(path)
        // supported repeatables (eg. id_document[*] -> [id_document[0], id_document[1], ...])
        ? convertPathToKeySignature(path, [i])
        // unsupported repeatables force 0th slot, relying on dedupe/uniq below
        // (eg. deceased.phone[*].phone_number -> [deceased.phone[0].phone_number, deceased.phone[0].phone_number, ...])
        : (isOfList(path) ? convertPathToKeySignature(path, [0]) : path)))
    .flatten()
    .uniq()
    .sort()
    .value()
}

export function groupAppFieldKeysIntoKeySets(keys: TAppFieldValueKey[]): Record<string, IAppFieldKeySet> {
  return chain(keys)
    .reject(({model: {path}}) => !SECTIONS_IDS_BY_PATH[path])
    .groupBy(({model: {path}}) => SECTIONS_IDS_BY_PATH[path])
    .mapValues((keys, id) => ({
      id,
      keys,
    }))
    .value()
}

// todo: break sections, repeatables and container things into isolated files

export function discoverRepeatablePathFrom(path?: IAppField["path"]) {
  return findKey(REPEATABLE_APP_FIELD_CONTAINER_LABELS_BY_PATH, (_, k) => startsWith(path, k))
}

export function isRepeatable(path?: IAppField["path"]) {
  return !!discoverRepeatablePathFrom(path)
}

export function bundleRepeatedKeysInKeySets(keySets: IAppFieldKeySet[]): ICompositeAppFieldKeySet["keySets"] {
  return map(keySets, keySet => {
    const pathForFirstKey = first(keySet.keys)?.model.path
    if (!isRepeatable(pathForFirstKey)) {
      return keySet
    }

    const repeatableKey = discoverRepeatablePathFrom(pathForFirstKey)
    return {
      id: keySet.id,
      repeatable_by: repeatableKey as TRepeatableTypes,
      keySets: chain(keySet.keys)
        .groupBy(({model: {path}, signature}) => {
          // parse indices from signature
          const indices = extractIndicesFromSignature(signature)
          // revive indices into "repeatable portion" of this key
          let i = 0
          // @ts-ignore - replaceAll()
          return repeatableKey.replaceAll("[*]", () => `[${indices[i++]}]`)
        })
        .map((keys, id) => ({id, keys}) as IAppFieldKeySet)
        .sortBy("keys.0.indices.0") // relies on having indices extracted from signature onto key
        .value(),
    } as IRepeatableAppFieldKeySets
  })
}

export function mergeListsOfKeys(listsOfKeys: TAppFieldValueKey[][]) {
  return chain(listsOfKeys)
    .flatten()
    .uniqBy("signature")
    .sortBy(({model: {path}}) => indexOf(GLOBAL_ORDERED_PATHS, path))
    .value()
}

export function mergeAppFieldKeySets(appFieldKeySets: IAppFieldKeySet[]) {
  if (!appFieldKeySets.length) {
    return null
  }

  return {
    id: first(appFieldKeySets)!.id,
    keys: mergeListsOfKeys(map(appFieldKeySets, "keys")),
  } as IAppFieldKeySet
}

export function mergeRepeatableAppFieldKeySets(repeatableKeySets: IRepeatableAppFieldKeySets[]) {
  if (!repeatableKeySets.length) {
    return null
  }

  return {
    id: first(repeatableKeySets)!.id,
    repeatable_by: first(repeatableKeySets)!.repeatable_by,
    keySets: chain(repeatableKeySets)
      .map("keySets")
      .flatten()
      .groupBy("id")
      .map(mergeAppFieldKeySets)
      .orderBy("keys.0.indices.0")
      .value(),
  } as IRepeatableAppFieldKeySets
}

/** Merge keySets of same type; eg. list of `IRepeatableAppFieldKeySets` or list of `IAppFieldKeySet` */
export function mergeDuplicateKeySets(
  keySets: ICompositeAppFieldKeySet["keySets"],
): ICompositeAppFieldKeySet["keySets"] {
  return chain(keySets)
    .groupBy("id")
    .map(groupingOfSameKeySet =>
      isRepeatableKeySets(first(groupingOfSameKeySet)!)
        ? mergeRepeatableAppFieldKeySets(groupingOfSameKeySet as IRepeatableAppFieldKeySets[])
        : mergeAppFieldKeySets(groupingOfSameKeySet as IAppFieldKeySet[]),
    )
    .compact()
    .sortBy(keySet =>
      indexOf(
        GLOBAL_ORDERED_PATHS,
        isRepeatableKeySets(keySet) ? keySet.keySets[0].keys[0].model.path : keySet.keys[0].model.path,
      ),
    )
    .value()
}

export function convertSignaturesToOrderedKeys(signatures: TAppFieldValueKey["signature"][]) {
  return chain(signatures)
    .map(signature => createKeyFromSignature(signature))
    .sortBy(({model: {path}}) => indexOf(GLOBAL_ORDERED_PATHS, path))
    .value()
}

export function convertKeySignatureToPath(signature: TAppFieldValueKey["signature"]) {
  return signature.replace(/\[\d+]/g, "[*]")
}

export function extractIndicesFromSignature(signature: TAppFieldValueKey["signature"]) {
  return map([...signature.matchAll(/\[(\d+)]/g)], matches => +matches[1])
}

export function convertPathToKeySignature(path: IAppField["path"], indices: TAppFieldValueKey["indices"]) {
  if (!indices) {
    return path
  }

  const indicesStack = indices.slice()
  return path.replace(/\[\*]/g, () => `[${indicesStack.shift() || "0"}]`)
}
